import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated MongoDB Developers | Offshore MongoDB Development Company in India',
    metaDescription: 'Outsource MongoDB Developer & programmers from India to develop secure, high performing database based applications at the lowest possible cost. Hire now!',
    h1: 'Hire Dedicated MongoDB Developers',
    h1Subtext1: 'Build modern applications using powerful cloud database with MatteCurve.',
    h1Subtext2: 'MatteCurve is the best MongoDB development company in India. We are a JavaScript company and have dedicated MongoDB developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire MongoDB developers within 7 days.',
    aboutSection: {
        title: 'About MongoDB',
        subTitle: 'A most popular document database for modern applications.',
        paragraph1: `
        MongoDB is a document database, which means it stores data in JSON-like documents. 
        We believe this is the most natural way to think about data, and is much more expressive and powerful than the traditional row/column model.
        `,
        paragraph2: `
        MongoDB supports rich JSON documents. It provides a powerful query language to fetch, write, and delete records.
        It also supports relational database capabilities like ACID transactions, joins in queries.
        MongoDB also provided powerful aggregation queries to aggregate results on millions of records.
        `,
        paragraph3: `
        An experience MongoDB developer is a key person who knows how to build a scalable mongodb database for an application.
        There is much more than writing simple crud queries. A mongodb developer should have experience in setting up indexes correctly,
        using transactions, locking database in some use cases and perfectly modeling the data into multiple documents to support millions of records.
        Hire MongoDB developers from MatteCurve who are experienced and skilled in developing large scale enterprise applications using MongoDB database.
        `,
        keyPoints: [
            "MongoDB is a reach document database.",
            "MongoDB provides a powerful query language with joins support, aggregations and other modern use-cases such as geo-based search, graph search, and text search.",
            "MongoDB supports distributed multi-document ACID transactions with snapshot isolation.",
            "MongoDB supports indexing to improve query performance.",
            "MongoDB is a NO-SQL database.",
            "MongoDB provides high availability with replica sets.",
            "MongoDB scales horizontally using sharding.",
            "MongoDB can be used as a file system, called GridFS, with load balancing and data replication features over multiple machines for storing files.",
            "JavaScript can be used in queries, aggregation functions (such as MapReduce), and sent directly to the database to be executed."
        ],
    },
    statsSection: {
        paragraph: "According to an estimate, approximately 8 thousand companies worldwide are using React.js for implementing web applications and mobile apps. The list includes some top mobile apps that were built using React Native are Facebook, Instagram, Netflix, Whatsapp, Yahoo, NewYorkTimes, and so on. Hire Dedicated MongoDB Developers in India from MatteCurve to grow by 4X.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated MongoDB Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated MongoDB Developers?",
        "Hire dedicated MongoDB Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for MongoDB Development?",
        paragraph: "MatteCurve has been recognized as one of the leading web development companies in building high-performance MongoDB apps from India. MatteCurve offers you deep expertise in MongoDB web development and MongoDB app development, delivered by a team of highly-skilled MongoDB Developers with unmatched expertise in building progressive and dynamic web apps using MongoDB.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
